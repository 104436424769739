var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value)?_c('div',{staticClass:"py-4 flex items-center"},[(_vm.value.total > 0)?_c('span',{staticClass:"text-gray-dark flex items-center"},[_vm._v(" "+_vm._s(_vm.value.from)+"-"+_vm._s(_vm.value.to)+" of "+_vm._s(_vm.value.total)+" "),(_vm.value.total > _vm.value.per_page)?_c('span',[_c('i',{staticClass:"material-icons p-2 ml-1",class:_vm.value.prev_page_url
            ? 'cursor-pointer rounded-full hover:bg-gray hover:shadow text-gray-darker'
            : 'text-gray pointer-events-none',on:{"click":function($event){return _vm.$store.commit((_vm.module + "/nextPage"), -1)}}},[_vm._v("keyboard_arrow_left")]),_c('i',{staticClass:"material-icons p-2 mr-1",class:_vm.value.next_page_url
            ? 'cursor-pointer rounded-full hover:bg-gray hover:shadow text-gray-darker'
            : 'text-gray pointer-events-none',on:{"click":function($event){return _vm.$store.commit((_vm.module + "/nextPage"), 1)}}},[_vm._v("keyboard_arrow_right")])]):_vm._e()]):_c('span',[_vm._v("No results")]),_c('span',{staticClass:"mx-1"},[_vm._v("in")]),_vm._t("label",function(){return [_c('i',{staticClass:"font-bold",domProps:{"textContent":_vm._s(
        ((_vm.capitalize(_vm.$store.state[_vm.module].pagination.view)) + " " + (_vm.capitalize(
          _vm.module
        )))
      )}})]}),(_vm.$store.state[_vm.module].pagination.search)?_c('span',{staticClass:"mx-1"},[_vm._v(" searching \""),_c('strong',{domProps:{"innerHTML":_vm._s(_vm.$store.state[_vm.module].pagination.search)}}),_vm._v("\" ")]):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }