<template>
  <div v-if="value" class="py-4 flex items-center">
    <span v-if="value.total > 0" class="text-gray-dark flex items-center">
      {{ value.from }}-{{ value.to }} of {{ value.total }}
      <span v-if="value.total > value.per_page">
        <i
          :class="
            value.prev_page_url
              ? 'cursor-pointer rounded-full hover:bg-gray hover:shadow text-gray-darker'
              : 'text-gray pointer-events-none'
          "
          class="material-icons p-2 ml-1"
          @click="$store.commit(`${module}/nextPage`, -1)"
          >keyboard_arrow_left</i
        >
        <i
          :class="
            value.next_page_url
              ? 'cursor-pointer rounded-full hover:bg-gray hover:shadow text-gray-darker'
              : 'text-gray pointer-events-none'
          "
          class="material-icons p-2 mr-1"
          @click="$store.commit(`${module}/nextPage`, 1)"
          >keyboard_arrow_right</i
        >
      </span>
    </span>
    <span v-else>No results</span>
    <span class="mx-1">in</span>
    <slot name="label">
      <i
        class="font-bold"
        v-text="
          `${capitalize($store.state[module].pagination.view)} ${capitalize(
            module
          )}`
        "
      />
    </slot>
    <span v-if="$store.state[module].pagination.search" class="mx-1">
      searching &quot;<strong
        v-html="$store.state[module].pagination.search"
      />&quot;
    </span>
  </div>
</template>

<script>
import { capitalize } from '@/utils/string';

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    module: {
      type: String,
      required: true,
    },
  },

  methods: {
    capitalize,
  },
};
</script>
