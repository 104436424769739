<template>
  <span :class="{ 'justify-center': !left }" class="flex items-center">
    <i
      v-show="sort.by === by"
      class="material-icons"
      v-text="sort.dir === 'asc' ? 'arrow_upward' : 'arrow_downward'"
    />
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    sort: { type: Object, required: true },
    by: { type: String, required: true },
    left: { type: Boolean, default: false },
  },
};
</script>
