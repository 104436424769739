<template>
  <div class="bg-gray-light p-4">
    <header
      v-if="selected.length > 0"
      class="
        mdc-top-app-bar mdc-top-app-bar--fixed
        bg-gray-darker
        fixed
        top-0
        left-0
        right-0
        z-50
      "
    >
      <div class="mdc-top-app-bar__row max-w-screen-xl px-8">
        <section
          class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start"
        >
          <div class="hidden md:inline w-48" />
          <span class="flex items-center">
            <i
              class="
                material-icons
                cursor-pointer
                mr-4
                p-2
                rounded-full
                hover:bg-gray
              "
              @click="toggleAll(false)"
              >clear</i
            >
            <span v-text="selected.length + ' selected'" />
          </span>
        </section>
        <section
          class="
            mdc-top-app-bar__section
            mdc-top-app-bar__section--align-end
            mdc-top-app-bar__section--shrink-to-fit
          "
        >
          <mdc-menu
            v-if="
              paginate.view !== 'groups' &&
              $store.state.contacts.groups.length > 0
            "
            fixed
          >
            <mdc-button
              slot="trigger"
              class="mr-2"
              raised
              title="Add selected contacts to a group..."
            >
              <i class="material-icons mdc-top-app-bar__icon--menu align-middle"
                >group_add</i
              >
              <!-- <span class="pl-1 hidden md:inline-block">Add to Group</span> -->
              <i class="material-icons mdc-top-app-bar__icon--menu align-middle"
                >keyboard_arrow_down</i
              >
            </mdc-button>
            <template slot="list">
              <mdc-button
                v-for="group in $store.state.contacts.groups"
                :key="group.hash"
                ripple
                class="mdc-list-item w-full"
                role="menuitem"
                @click.native="addSelectedToGroup(group)"
              >
                <i
                  class="material-icons mdc-list-item__graphic"
                  aria-hidden="true"
                  >group</i
                >
                <span class="mdc-list-item__text" v-text="group.name" />
              </mdc-button>
            </template>
          </mdc-menu>
          <mdc-button
            v-if="selectedGroup"
            :title="`Remove selected contacts from group '${selectedGroup.name}'`"
            class="mr-2"
            raised
            secondary
            @click.native="removeFromGroup"
          >
            <svg-group-remove class="w-6 text-white" />
            <!-- <span class="hidden md:inline-block">Remove from Group</span> -->
          </mdc-button>
          <mdc-button
            v-if="paginate.view === 'disabled'"
            raised
            title="Enable selected contacts"
            class="mr-2"
            @click.native="enableSelected"
          >
            <i class="material-icons">person_outline</i>
            <!-- <span class="hidden md:inline-block">Enable</span> -->
          </mdc-button>
          <mdc-button
            v-else-if="paginate.view !== 'groups'"
            raised
            danger
            title="Disable selected contacts"
            class="mr-2"
            @click.native="disableSelected"
          >
            <i class="material-icons">person_outline</i>
            <!-- <span class="hidden md:inline-block">Disable</span> -->
          </mdc-button>
          <mdc-button
            raised
            danger
            title="Delete selected contacts"
            @click.native="deleteSelected"
          >
            <i class="material-icons">delete</i>
            <!-- <span class="hidden md:inline-block">Delete</span> -->
          </mdc-button>
        </section>
      </div>
    </header>

    <div class="overflow-x-auto">
      <blockquote class="note" v-if="paginate.view === 'disabled'">
        <p>
          Disabled contacts will not receive emails while they are on this list.
        </p>
        <p>
          This can be helpful to maintain a list of contacts that have
          unsubscribed from your emails.
        </p>
        <p>
          Also if a contact fails a certain number of times, they will become
          automatically disabled.
        </p>
        <p>
          You may re-enable disabled contacts, but be aware that if your bounce
          rate (invalid email addresses, spam complaints) reaches a certain
          threshold, your account could be suspended.
        </p>
      </blockquote>
      <table :style="{ minWidth: '600px' }" class="later-table">
        <thead>
          <tr>
            <th
              class="w-12"
              title="Contacts that are used in one or more email messages can't be deleted."
            >
              <mdc-checkbox v-model="toggler" @input="toggleAll(true)" />
            </th>
            <th
              class="w-64 cursor-pointer"
              colspan="2"
              @click="setSort('name')"
            >
              <later-col-sort :sort="paginate.sort" by="name" left>
                Name
              </later-col-sort>
            </th>
            <th
              v-if="paginate.view !== 'groups'"
              class="cursor-pointer pr-3"
              @click="setSort('email')"
            >
              <later-col-sort :sort="paginate.sort" by="email" left>
                Email
              </later-col-sort>
            </th>
            <th
              class="cursor-pointer pr-3"
              title="How many emails are currently addressed to this directly"
              @click="setSort('emails_count')"
            >
              <later-col-sort :sort="paginate.sort" by="emails_count">
                # Messages
              </later-col-sort>
            </th>
            <th
              v-if="paginate.view !== 'groups'"
              class="cursor-pointer pr-3"
              title="How many groups this contact is a part of"
              @click="setSort('groups_count')"
            >
              <later-col-sort :sort="paginate.sort" by="groups_count">
                # Groups
              </later-col-sort>
            </th>
            <th
              v-else
              class="cursor-pointer pr-3"
              title="How many contacts are in this group"
              @click="setSort('contacts_count')"
            >
              <later-col-sort :sort="paginate.sort" by="contacts_count">
                # Contacts
              </later-col-sort>
            </th>
            <th
              v-if="paginate.view === 'disabled'"
              class=""
              title="Reason for being disabled"
            >
              Reason
            </th>
          </tr>
        </thead>
        <tbody v-if="contacts" class="bg-white">
          <tr
            v-for="contact in contacts.data"
            :key="contact.hash"
            :class="{ 'text-gray': contact.deleted_at }"
            :title="contact.deleted_reason"
          >
            <td class="cursor-auto" @click.stop>
              <mdc-checkbox v-model="contact.checked" no-tab />
            </td>
            <td class="w-64">
              <input
                v-if="!contact.deleted_at"
                v-model="contact.name"
                type="text"
                class="rounded w-full py-1 leading-none truncate"
                @change="contact.dirty = true"
                @blur="updateName(contact)"
              />
              <span v-else v-text="contact.name" />
            </td>
            <td>
              <i
                v-if="contact.saving"
                class="material-icons later-spin text-secondary"
                >replay</i
              >
            </td>
            <td v-if="paginate.view !== 'groups'" v-html="contact.email" />
            <td class="text-center" v-html="contact.emails_count" />
            <td
              v-if="paginate.view !== 'groups'"
              class="text-center"
              v-html="contact.groups_count"
            />
            <td v-else class="text-center" v-html="contact.contacts_count" />
            <td
              v-if="paginate.view === 'disabled'"
              class="pr-3"
              v-text="contact.deleted_reason"
            />
          </tr>
        </tbody>
      </table>
    </div>

    <later-pagination v-if="contacts" v-model="contacts" module="contacts">
      <span slot="label">
        <strong v-if="paginate.view === 'all'">All Contacts</strong>
        <strong v-else-if="paginate.view === 'disabled'"
          >Disabled Contacts</strong
        >
        <strong v-else-if="paginate.view === 'groups'">All Groups</strong>
        <span v-else v-html="`group <strong>${selectedGroup.name}</strong>`" />
      </span>
    </later-pagination>

    <mdc-dialog
      v-if="newContactDialogOpen"
      classes="sm:w-64"
      type="form"
      title="Create New Contact"
      @submit="submitNewContact"
      @closing="newContactDialogOpen = false"
    >
      <mdc-textfield
        v-model="name"
        label="Name"
        type="text"
        class="w-full"
        autocomplete="name"
      />
      <mdc-textfield
        v-model="email"
        label="Email"
        type="email"
        class="w-full"
        required
        autocomplete="username email"
      />
    </mdc-dialog>

    <mdc-dialog
      v-if="importContactsDialogOpen"
      type="form"
      title="Import Contacts From CSV File"
      @submit="submitImport"
      @closing="importContactsDialogOpen = false"
    >
      <input ref="input" type="file" accept=".csv" />
    </mdc-dialog>

    <mdc-dialog
      v-if="newGroupDialogOpen"
      type="form"
      title="Create a New Group"
      @submit="submitNewGroup"
      @closing="newGroupDialogOpen = false"
    >
      <mdc-textfield
        v-model="gname"
        label="Name"
        type="text"
        class="w-full"
        required
        autocomplete="name"
      />
    </mdc-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import axios from '@/utils/xhr';
import MdcButton from '@/components/mdc/button.vue';
import MdcCheckbox from '@/components/mdc/checkbox.vue';
import MdcDialog from '@/components/mdc/dialog.vue';
import MdcTextfield from '@/components/mdc/textfield.vue';
import MdcMenu from '@/components/mdc/menu.vue';
import LaterColSort from '@/components/col-sort.vue';
import LaterPagination from '@/components/pagination.vue';
import SvgGroupRemove from '@/svg/icons/group-remove.svg';
import { alertDialog, confirmDialog } from '@/utils/dialog';

export default {
  components: {
    LaterColSort,
    LaterPagination,
    MdcButton,
    MdcCheckbox,
    MdcDialog,
    MdcMenu,
    MdcTextfield,
    SvgGroupRemove,
  },

  data: () => ({
    contacts: null,
    newContactDialogOpen: false,
    importContactsDialogOpen: false,
    newGroupDialogOpen: false,
    mdcToolbar: null,
    menuAddToGroup: null,
    toggler: false,
    name: '',
    email: '',
    gname: '',
    unsubscribe: null,
  }),

  computed: {
    selected() {
      return this.contacts == null
        ? []
        : this.contacts.data.filter((contact) => contact.checked);
    },
    ...mapState('contacts', {
      paginate: (state) => state.pagination,
      groups: (state) => state.groups,
    }),
    ...mapGetters('contacts', ['selectedGroup']),
  },

  mounted() {
    this.fetch();
    this.getGroups();

    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'contacts/setView') this.fetch();
      else if (mutation.type === 'contacts/setSearch') this.fetch();
      else if (mutation.type === 'contacts/nextPage') this.fetch();
      else if (mutation.type === 'contacts/newContact')
        this.newContactDialogOpen = true;
      else if (mutation.type === 'contacts/importContacts')
        this.importContactsDialogOpen = true;
      else if (mutation.type === 'contacts/newGroup')
        this.newGroupDialogOpen = true;
    });
  },

  beforeDestroy() {
    this.unsubscribe();
  },

  methods: {
    paginationLabel() {
      let str = '';
      if (this.paginate.view === 'all') str = 'All Contacts';
      else if (this.paginate.view === 'groups') str = 'All Groups';
      else str = `group <strong>${this.selectedGroup.name}</strong>`;
      return str;
    },
    fetch() {
      if (this.paginate.view === 'groups' && this.groups.length === 0)
        this.$store.commit('contacts/setView', 'all');
      this.toggler = false;
      axios
        .post(
          `/email/contacts${
            this.paginate.view === 'groups' ? '/groups' : ''
          }/results`,
          { ...this.paginate }
        )
        .then((response) => {
          response.data.data.forEach((contact, i) => {
            response.data.data[i].checked = false;
            response.data.data[i].dirty = false;
            response.data.data[i].saving = false;
          });
          this.$set(this, 'contacts', response.data);
          if (['all', 'disabled'].includes(this.paginate.view))
            this.$store.commit('contacts/setTotal', response.data.total);
          document.body.scrollTop = 0;
        });
    },
    getGroups() {
      axios.get('/email/contacts/groups').then((response) => {
        this.$store.commit('contacts/groups', response.data);
      });
    },
    updateName(contact) {
      if (!contact.dirty) return;
      contact.saving = true;
      axios
        .put(
          `/email/contacts${this.paginate.view === 'groups' ? '/groups' : ''}/${
            contact.hash
          }`,
          { name: contact.name }
        )
        .then((response) => {
          contact.saving = false;
          contact.dirty = false;
          if (this.paginate.view === 'groups')
            this.$store.commit('contacts/groups', response.data);
        });
    },
    submitNewContact() {
      axios
        .post('/email/contacts/create', { name: this.name, email: this.email })
        .then((response) => {
          this.name = '';
          this.email = '';

          let msg = '';
          if (response.data.action === 'inserted')
            msg = 'New contact has been created.';
          else if (response.data.deleted_at)
            msg = 'Contact already exists, but is disabled.';
          else if (response.data.action === 'updated')
            msg = 'Contact already exists, name was updated.';
          else if (response.data.action === 'selected')
            msg = 'Contact already exists.';

          this.$store.commit('snackbarMessage', msg);
          this.fetch();
          this.newContactDialogOpen = false;
        });
    },
    submitImport() {
      if (this.$refs.input.files && this.$refs.input.files.length > 0) {
        const formData = new FormData();
        formData.append('csvfile', this.$refs.input.files[0]);
        this.$refs.input.value = null;
        axios.post('/email/contacts/import', formData).then((response) => {
          this.importContactsDialogOpen = false;
          alertDialog(
            'Import Successful!',
            `
            ${response.data.total} total contacts uploaded. <br>
            ${response.data.inserted} contacts are new. <br>
            ${response.data.selected} contacts were already here. <br>
            ${response.data.updated} contacts names were updated. <br>
            ${response.data.disabled} contacts are on your disabled list. <br>
            ${response.data.error} contacts incorrectly formated. <br>
          `
          );
          this.fetch();
        });
      }
    },
    submitNewGroup() {
      axios
        .post('/email/contacts/groups/create', { name: this.gname })
        .then((response) => {
          this.gname = '';
          this.$store.commit('snackbarMessage', 'Group has been created.');
          this.$store.commit('contacts/groups', response.data);
          this.newGroupDialogOpen = false;
          this.fetch();
        });
    },
    addSelectedToGroup(group) {
      axios
        .post(`/email/contacts/groups/${group.hash}/add`, {
          contacts: this.selected.map((contact) => contact.hash),
        })
        .then((response) => {
          this.$store.commit(
            'snackbarMessage',
            `Selected contact${
              this.selected.length !== 1 ? 's have' : ' has'
            } been added to group "${group.name}".`
          );
          this.$store.commit('contacts/groups', response.data);
        });
    },
    removeFromGroup() {
      axios
        .post(`/email/contacts/groups/${this.selectedGroup.hash}/remove`, {
          contacts: this.selected.map((contact) => contact.hash),
        })
        .then((response) => {
          this.$store.commit(
            'snackbarMessage',
            `Selected contact${
              this.selected.length !== 1 ? 's have' : ' has'
            } been removed from group "${this.selectedGroup.name}".`
          );
          this.$store.commit('contacts/groups', response.data);
        });
    },
    nextPage(direction) {
      this.$store.commit('contacts/nextPage', direction);
      this.fetch();
    },
    toggleAll(check) {
      if (check) check = this.toggler;
      else this.toggler = check;
      this.contacts.data.forEach((contact, i) => {
        this.contacts.data[i].checked = check;
      });
    },
    setSort(by) {
      this.$store.commit('contacts/setSort', by);
      this.fetch();
    },
    disableSelected() {
      confirmDialog(`Disable ${this.selected.length} Contacts`, 'Are you sure?')
        .then(() => {
          axios
            .post('/email/contacts/disable', {
              contacts: this.selected.map((contact) => contact.hash),
            })
            .then(() => {
              this.$store.commit(
                'snackbarMessage',
                'Selected contacts have been disabled.'
              );
              this.fetch();
              this.getGroups();
            });
        })
        .catch(() => {});
    },
    enableSelected() {
      axios
        .post('/email/contacts/enable', {
          contacts: this.selected.map((contact) => contact.hash),
        })
        .then(() => {
          this.$store.commit(
            'snackbarMessage',
            'Selected contacts have been enabled.'
          );
          this.fetch();
          this.getGroups();
        });
    },
    deleteSelected() {
      let howManyWhat = `${this.selected.length} ${
        this.paginate.view === 'groups' ? 'group' : 'contact'
      }${this.selected.length === 1 ? '' : 's'}`;

      confirmDialog(`Delete ${howManyWhat}?`, 'Are you sure?')
        .then(() => {
          const contacts = this.selected
            .filter((contact) => contact.emails_count === 0)
            .map((contact) => contact.hash);
          if (contacts.length < this.selected.length) {
            this.$store.commit(
              'snackbarMessage',
              `${
                this.paginate.view === 'groups' ? 'Groups' : 'Contacts'
              } cannot be deleted if they are in use in any emails (even in trash).`
            );
            if (contacts.length === 0) return;
          }
          axios
            .post(
              `/email/contacts${
                this.paginate.view === 'groups' ? '/groups' : ''
              }/delete`,
              { contacts }
            )
            .then((response) => {
              this.$store.commit('snackbarMessage', `Deleted ${howManyWhat}.`);
              if (this.paginate.view === 'groups')
                this.$store.commit('contacts/groups', response.data);
              this.fetch();
              this.getGroups();
            });
        })
        .catch(() => {});
    },
  },
};
</script>
